<template>
    <div>
        <navAdmin />

        <div class="container">
            <h3 class="h2 pt-4 mb-3">
                <span>Promo codes</span>
                <router-link class="btn btn-success float-right" :to="{ name: 'CreateDiscount' }">Create</router-link>
            </h3>
            <p class="h4 pt-3" v-if="getPromoCodes.length == 0 && !isPromoCodesLoading">Nothing found!</p>
            <b-table
                v-if="!isPromoCodesLoading && getPromoCodes.length > 0"
                id="events"
                :items="getPromoCodes"
                :fields="fields"
                :per-page="getPromoCodes.length"
                :current-page="1"
            >
                <template v-slot:cell(title)="data">
                    {{ data.item.title }}
                </template>

                <template v-slot:cell(code)="data">
                    {{ data.item.code }}
                </template>

                <template v-slot:cell(value)="data">
                    {{ data.item.value }}
                </template>
                
                <template v-slot:cell(expiration_date)="data">
                    {{ convertBrouserTZDateOnly(data.item.expiration_date) }}
                </template>

                <template v-slot:cell(is_paused)="data">
                    <b-form-checkbox switch @change="pausePromo(data.item.id)" :value="0" v-model="data.item.is_paused" size="lg"></b-form-checkbox>
                </template>
                
                <template v-slot:cell(orders_total)="data">
                    {{ data.item.orders_total }}
                </template>

                <template v-slot:cell(controls)="data"> 
                    <button @click="deletePromo(data.item.id)" class="btn btn-danger float-right">Delete</button>
                    <router-link class="btn btn-primary float-right mr-1" :to="{ name: 'EditDiscount', params: { 'code_id': data.item.id } }">Edit</router-link>
                </template>
            </b-table>
        </div>
        <delete-popup ref="deletePromoPopup" @saved="saved" />
        <pause-popup ref="pausePrompPopup" @saved="saved" />
    </div>
</template>
<script>
import navAdmin from './../../components/nav-admin';
import deletePopup from './DeleteDiscount';
import pausePopup from './PauseDiscount';
import { userType } from '../../mixins/user-type.mixin';
import { timeZone } from '../../mixins/time-zone.mixin';
import { mapGetters, mapActions } from 'vuex';

export default {
    mixins: [userType, timeZone],
    computed: mapGetters([
      'getPromoCodes',
      'isPromoCodesLoading'
    ]),
    data() {
        return {
            fields: [
                { key: 'title', label: "Title" },
                { key: 'code', label: "Code" },
                { key: 'value', label: "Value" },
                { key: 'expiration_date', label: "Expiaration date" },
                { key: 'is_paused', label: "Active"},
                { key: 'orders_total', label: "Orders" },
                { key: 'controls', label: "" }
            ]
        }
    },
    components: { navAdmin, deletePopup, pausePopup },
    methods: { 
        ...mapActions(['fetchCodes']),
        deletePromo(id) {
            this.$refs.deletePromoPopup.show();
            this.$refs.deletePromoPopup.id = id;
        },
        pausePromo(id) {
            this.$refs.pausePrompPopup.show();
            this.$refs.pausePrompPopup.id = id;
        },
        saved() {
            this.fetchCodes();
        }
    },
    mounted() {
        this.fetchCodes();
    }
}
</script>
<style scoped>
    .container h3 {
        padding-right: 20px;
    }
</style>