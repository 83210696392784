<template>
    <b-modal :id="documentId" title="Delete Promo Code">
        <b-container fluid>
            <div class="alert alert-warning">
                <h3 class="h4">Attention!</h3>
                <p>Your Promo Code is going to be deleted. Are you shure?</p>
            </div>
        </b-container>    
        <template v-slot:modal-footer="{ cancel }">
            <b-button variant="danger" @click="drop">Delete</b-button>
            <b-button  @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>
<script>
import { notificationMixin } from '../../mixins/notification.mixin';
import { mapActions, mapGetters } from 'vuex';
export default {
    mixins: [ notificationMixin ],
    computed: mapGetters(['isDiscountCodeActionSuccess']),
    data() {
        return {
          documentId: 'delete-promo-code'
        }
    },
    props: {
        id: {
            type: Number,
            default: 0
        }
    },
    methods: {
        ...mapActions([
            'deleteCode'
        ]),
        show() {
            this.$bvModal.show(this.documentId);
        },
        async drop() {
            this.deleteCode(this.id);
            if(this.isDiscountCodeActionSuccess) {
                this.successNtf("Success!", "Promo Code deleted!");
                this.$bvModal.hide(this.documentId);
                this.$emit('saved', true);
            } else {
                this.errorNtf("Error!", "Somthing went wrong!");
                this.$bvModal.hide(this.documentId);
                this.$emit('saved', false);
            }
        }
    }
}
</script>
