<template>
    <b-modal :id="documentId" title="Delete Promo Code">
        <b-container fluid>
            <div class="alert alert-warning">
                <h3 class="h4">Attention!</h3>
                <p>Are you shure?</p>
            </div>
        </b-container>    
        <template v-slot:modal-footer="{ cancel }">
            <b-button @click="update">Save</b-button>
            <b-button @click="cancel()">Cancel</b-button>
        </template>
    </b-modal>  
</template>
<script>
import { notificationMixin } from '../../mixins/notification.mixin';
import { mapActions, mapGetters } from 'vuex';
export default {
    mixins: [ notificationMixin ],
    computed: mapGetters([
      'isDiscountCodeActionSuccess',
      'getPromoCodeItem'
      ]),
    data() {
        return {
          documentId: 'pause-promo-code'
        }
    },
    props: {
        id: {
            type: Number,
            default: 0
        }
    },
    methods: {
        ...mapActions([
            'fectchCode',
            'updateCode'
        ]),
        show() {
            this.$bvModal.show(this.documentId);
        },
        async update() {
            await this.fectchCode(this.id);
            let model = this.getPromoCodeItem;
            model.is_paused = !model.is_paused;
            await this.updateCode(model);
            
            if(this.isDiscountCodeActionSuccess) {
                this.successNtf("Success!", "Promo Code updated!");
                this.$bvModal.hide(this.documentId);
                this.$emit('saved', true);
            } else {
                this.errorNtf("Error!", "Somthing went wrong!");
                this.$bvModal.hide(this.documentId);
                this.$emit('saved', false);
            }
        }
    }
}
</script>
